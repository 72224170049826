import React from 'react';
import QrReader from 'react-qr-reader';

var firebase = require("firebase/app");
var auth = require("firebase/auth");
var database = require("firebase/database");

export default class FromCustomer extends React.Component {

  state = {
    valueplace: '',
    valuename: '',
  };

  handleUserChange = (user) => {
    if (user) {
      this.setState ({
        user:user,
        message: '',
        stage: 'enterplace'
      });

      var date = new Date();
      var dateStr = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
      var timeStr = date.getHours() + ":" + date.getMinutes();

      firebase.database().ref('/users/'+this.state.user.phoneNumber).update({
        lastsession: dateStr + ' ' + timeStr
      });

    } else {
      this.setState ({
        user:'',
        message: 'אנא הזינו מספר טלפון נייד, כדי שנוכל לזהות אתכם',
        stage:'enterphone'});
    }
  }


  constructor(props) {
    super(props);

    var firebaseConfig = {
      apiKey: "AIzaSyAGDkg8qGqgOALEFi75Kl4yBklSJmJQO1I",
      authDomain: "whowashere-8d3b8.firebaseapp.com",
      databaseURL: "https://whowashere-8d3b8.firebaseio.com",
      projectId: "whowashere-8d3b8",
      storageBucket: "whowashere-8d3b8.appspot.com",
      messagingSenderId: "874620106895",
      appId: "1:874620106895:web:e9b473e9d5bf5a00c918fa",
      measurementId: "G-DPPZMPNSDS"
    };

    // Initialize Firebase
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }

    this.state.app = props.app;
    var user = firebase.auth().currentUser;
    if (user) {
      this.setState ({
        user,
      });
    }
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged( (user) => this.handleUserChange(user));
  }

  addNames(event) {
    this.setState({names: this.state.names+1});
  }

  handleChange = (event) => {
    this.setState({[event.target.name] : event.target.value });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    var date = new Date();
    var dateStr = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
    var timeStr = date.getHours() + ":" + date.getMinutes();

    if (this.state.stage === 'checkout') {
      firebase.database().ref('/places/'+this.state.valueplace).child('visits/'+this.state.key).update({
        leave: dateStr + ' ' + timeStr
      });

      firebase.database().ref('/users/'+this.state.user.phoneNumber).update({
        checkout: dateStr + ' ' + timeStr
      });
      firebase.database().ref('/users/'+this.state.user.phoneNumber+'/visits/places/'+this.state.valueplace+'/'+dateStr+'/'+this.state.user_visit_place_key).update({
        checkout: dateStr + ' ' + timeStr
      });
      firebase.database().ref('/users/'+this.state.user.phoneNumber+'/visits/dates/'+dateStr+'/'+this.state.valueplace+'/'+this.state.user_visit_date_key).update({
        checkout: dateStr + ' ' + timeStr
      });

      this.setState ({
        stage: 'left',
        message: 'תודה על הביקור בבית העסק'
      });
      return;
    }

    if (this.state.stage === 'left') {
      this.state.app.setState ({
        // page: 'entry'
      });
      return;
    }


  }

  savePhoneCodePressed = () => {
    if (!this.state.valuephone) {
      alert ('אנא הזינו את הקוד שקיבלתם בסמס');
      return;
    }
    this.setState ({
      message: 'אנחנו בודקים את הקוד שהזנתם'
    });

    if (this.state.confirmationResult) {

      this.state.confirmationResult.confirm(this.state.valuephonecode).then( (result) => {
          // User signed in successfully.
          this.setState ({
            message: 'הקוד אושר, אפשר להמשיך',
            user : result.user,
            stage: 'enterplace'
          });
      }).catch( (error) => {
        this.setState ({
          message: error.message
        })
      });
    }
  }

  sendPhonePressed = () => {
    if (!this.state.valuephone) {
      alert ('אנא הזינו את מספר הטלפון נייד שלכם');
      return;
    }

    var phone = this.state.valuephone;
    if ( phone.startsWith('05') || phone.startsWith('07') ) {
      phone = '+972' + phone.substr(1);
    }
    else {
      alert ('אנחנו תומכים רק במכשירים ניידים - לכן המספר צריך להתחיל ב 05 או ב 07');
      return;
    }
    firebase.auth().languageCode = 'he';

    var appVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
      'size': 'invisible',
      'callback': (response) => {
        // this.setState ({'stage':'enterphonecode', message:'אנו נשלח לך קוד בסמס, אותו יש להזין'});
      }
    });

    firebase.auth().signInWithPhoneNumber(phone, appVerifier)
      .then( (confirmationResult) => {
        this.state.confirmationResult = confirmationResult;
        this.setState ({'stage':'enterphonecode', message:'אנו נשלח לך קוד בסמס, אותו יש להזין'});
      }).catch( (error) => {
        this.setState ({message: error.message});
      });

  }


  checkinPressed = () => {
    var date = new Date();
    var dateStr = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
    var timeStr = date.getHours() + ":" + date.getMinutes();

      var key = firebase.database().ref('/places/'+this.state.valueplace).child('visits/'+dateStr).push({
        'phone' : this.state.user.phoneNumber,
        'enter' : dateStr + ' ' + timeStr
      }).key;

      var user_visit_place_key = firebase.database().ref('/users/'+this.state.user.phoneNumber+'/visits/places/'+this.state.valueplace+'/'+dateStr).push({
        checkin: dateStr + ' ' + timeStr
      }).key;
      var user_visit_date_key = firebase.database().ref('/users/'+this.state.user.phoneNumber+'/visits/dates/'+dateStr+'/'+this.state.valueplace).push({
        checkin: dateStr + ' ' + timeStr
      }).key;

      this.setState ({
        key: dateStr+'/'+key,
        message: 'הנך מוזמן/ת לבקר בבית העסק',
        stage: 'checkout',
        user_visit_place_key,
        user_visit_date_key
      });
  }
  checkoutPressed = () => {
    var date = new Date();
    var dateStr = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
    var timeStr = date.getHours() + ":" + date.getMinutes();

      firebase.database().ref('/places/'+this.state.valueplace).child('visits/'+this.state.key).update({
        leave: dateStr + ' ' + timeStr
      });

      firebase.database().ref('/users/'+this.state.user.phoneNumber).update({
        checkout: dateStr + ' ' + timeStr
      });
      firebase.database().ref('/users/'+this.state.user.phoneNumber+'/visits/places/'+this.state.valueplace+'/'+dateStr+'/'+this.state.user_visit_place_key).update({
        checkout: dateStr + ' ' + timeStr
      });
      firebase.database().ref('/users/'+this.state.user.phoneNumber+'/visits/dates/'+dateStr+'/'+this.state.valueplace+'/'+this.state.user_visit_date_key).update({
        checkout: dateStr + ' ' + timeStr
      });

      this.setState ({
        stage: 'left',
        message: 'תודה על הביקור בבית העסק'
      });
  }
  handleScan = (data) => {
     if (data) {
       var place = data.replace("http://tav.greenshpits.com/?place=","");
       firebase.database().ref('/places/' +  place).once('value').then((snapshot) => {
         if (snapshot.val() && snapshot.val().name) {
           this.setState({
             'place': snapshot.val().name,
             'stage': 'checkin',
             'message': 'הצהרת בריאות בכניסה לבית העסק'
           });

           firebase.database().ref('/users/'+this.state.user.phoneNumber).update({
             nexttoplace: place,
             nexttoplacename: snapshot.val().name
           });

         }
       });
     }
   }
   handleError = (err) => {
     alert (err);
   }

  scanBarcodePressed = () => {
    this.setState ({
      user:'',
      message: 'סרקו את הקוד',
      stage:'scan'});
  }

  exitPressed =  () => {
    this.setState ({
      stage: 'enterplace',
      valueplace: '',
      message: ''
    });
  }

  enterPlacePressed = () => {
    const t = this;
      t.setState({'place':''});
      firebase.database().ref('/places/' +  this.state.valueplace).once('value').then((snapshot) => {
        if (snapshot.val() && snapshot.val().name) {
          t.setState({
            'place': snapshot.val().name,
            'stage': 'checkin',
            'message': 'הצהרת בריאות בכניסה לבית העסק'
          });

          firebase.database().ref('/users/'+this.state.user.phoneNumber).update({
            nexttoplace: this.state.valueplace,
            nexttoplacename: snapshot.val().name
          });
        }
        else {
          alert ('לא נמצא עסק עם קוד שכזה, אנא נסו שוב')
        }
      });
  }

  render() {
    // const s0 = "15px";
    const s1 = "20px";
    const s2 = "30px";

    return (
      <div dir="rtl"  style={{textAlign:'center', backgroundColor:'#800080', height:'100%', paddingLeft:'10%',paddingRight:'10%'}}>
      <div style={{height:'1%'}}/>
      { this.state.stage !== 'enterplace' &&
      <div style={{fontSize: s2,color:'white'}}>
      התו הסגול
      </div>
      }



      <div style={{marginTop:'5%',fontSize: s1, lineHeight:s1, color:'white', maxWidth:'400px'}}>
      {this.state.message}
      </div>

      { this.state.stage === 'enterphone'  &&
      <div>
      <br/>
      <input name="valuephone"
            placeholder="מספר טלפון נייד"
            onChange={this.handleChange}
            style={{marginTop:'3%',padding:'5%',width:'80%',fontSize:s2, lineHeight:s2}} type="text" value={this.state.valuephone} />
      <br/><br/><br/>
      <button onClick={this.sendPhonePressed}  style={{fontSize:s2, padding:'5%',height:'8%',width:'55%',backgroundColor:'#006400',color:'white'}}>
      שליחת מספר טלפון נייד
      </button>

      <div id='sign-in-button'/>
      </div>
      }

      { this.state.stage === 'scan' &&
        <QrReader
                delay={300}
                onError={this.handleError}
                onScan={this.handleScan}
                style={{ width: '100%',marginTop:'5%' }}
              />
      }
      { this.state.stage === 'enterphonecode'  &&
      <div>
      <br/>
      <input name="valuephonecode"
            placeholder="קוד שמגיע בסמס"
            style={{marginTop:'3%',padding:'5%',fontSize:s2, lineHeight:s2, width:'60%',textAlign:'center'}} type="text" value={this.state.valuephonecode} onChange={this.handleChange} />
      <br/><br/><br/>
      <button onClick={this.savePhoneCodePressed}  style={{fontSize:s2, padding:'5%',height:'8%',width:'55%',backgroundColor:'#006400',color:'white'}}>
      שליחת קוד זיהוי מהסמס
      </button>

      </div>
      }

      { this.state.stage === 'enterplace'  &&
        <div style={{textAlign:'center'}}>

        <p style={{marginTop:'5%',fontSize: s1, lineHeight:s1, textAlign:'center',color:'white', maxWidth:'400px'}}>
        ביחד ננצח את הקורונה
        <br/>
        מיישמים את הנחיות משרד הבריאות
        </p>
        <p style={{marginTop:'5%',fontSize: s2, lineHeight:s2,color:'white', maxWidth:'400px'}}>
        התו הסגול
        </p>


        <p dir="rtl" style={{marginTop:'2%',fontSize: s1, lineHeight:s1, textAlign:'right',color:'white', maxWidth:'400px'}}>
        לקוחות יקרים,
        <ol dir="RTL" style={{marginTop:'0px'}}>
        <li>
        טרם כניסתכם לבית עסק, הנכם נדרשים לאשר באפליקציה זו הצהרת בריאות.
        </li>
        <li>
        בהמשך, יופיע בפניכם המסך בו תתבקשו לעדכן על יציאתכם מבית העסק.
        </li>
        <li>
        מספר הטלפון שלכם נותר חסוי. במקרה הצורך בחקירה אפידמיולוגית, הוא יימסר אך ורק למשרד הבריאות, בכפוף לחוק.
        </li>
        <li>
        כדי לאתר את בית העסק בו תרצו לבקר אנא הזינו את הקוד שמופיע בכניסה אל בית העסק.
        </li>
        <li>
        אם מותקנת במכשירכם אפליקציה לסריקת ברקוד ביכולתכם להשתמש בה ולסרוק את הברקוד שמופיע בכניסה אל בית בעסק.
        </li>
        </ol>
         </p>
         <table style={{marginLeft:'3%',width:'100%'}}>
            <tr>
              <td>
              <input
                name="valueplace"
                placeholder= "הזינו כאן קוד בית עסק"
                style={{textAlign:'center',padding:'0px',fontSize:s1, lineHeight:s1,placeholderColor:'white',color:'white',backgroundColor:'#006400'}}
                type="text"
                value={this.state.valueplace}
                onChange={this.handleChange}
              />
              </td>
              <td>
              {this.state.valueplace !== '' &&
              <button
                onClick={this.enterPlacePressed}
                style={{padding:'1%',fontSize:s1, lineHeight:s1,backgroundColor:'#006400',color:'white'}}>
                כניסה
              </button>
              }
              </td>
            </tr>
          </table>
        </div>
      }

      { this.state.stage === 'left'  &&
      <div style={{marginTop:'5%'}}>
      <label style={{color:'white',fontSize: '30px'}}>
        {this.state.place}
      </label>
      <br/>
      <p dir="RTL" style={{color:'white',fontSize: s1}}>
        <br/>
        תודה על שיתוף הפעולה
        <br/>
        ומילוי אחר הוראות משרד הבריאות.
        <br/>
        ביחד, נתגבר על הקורונה.
        <br/>
        <br/>
        נבקש להזכירכם:
        <ol dir="RTL" style={{marginTop:'0px',textAlign:'right'}}>
        <li>
        הקפידו על ריחוק חברתי של שני מטרים בין אדם לאדם.
         </li>
         <li>
         כסו את האף והפה במסכה בכל יציאה מהבית.
        </li>
        <li>
        הקפידו לשטוף ידיים במים וסבון לעיתים קרובות.
       </li>
        </ol>
        <br/>
      </p>
          <button onClick={this.exitPressed}  style={{fontSize:s2, padding:'5%',height:'8%',width:'55%',backgroundColor:'#006400',color:'white'}}>
          לביקור בבית עסק נוסף, לחצ/י כאן
          </button>
          </div>
        }


      { this.state.stage === 'checkout'  &&
      <div style={{marginTop:'5%'}}>
      <label style={{color:'white',fontSize: '30px'}}>
        {this.state.place}
      </label>
      <br/>
      <label style={{color:'white',fontSize: s2}}>
        <br/>
        <br/>
        עם סיום ביקורך
        <br/>
        נא לחצ/י לעדכון יציאה מבית העסק
      </label>

      <br/><br/><br/>
          <button onClick={this.checkoutPressed}  style={{fontSize:s2, padding:'5%',height:'8%',width:'55%',backgroundColor:'#006400',color:'white'}}>
          סיימתי, אני יוצא מפה
          </button>
          </div>
        }

        { this.state.stage === 'checkin'  &&
          <div style={{marginTop:'5%'}}>
          <label style={{marginTop:'5%',color:'white',fontSize: '30px'}}>
            {this.state.place}
          </label>
          <br/>
          <p dir="rtl" style={{marginLeft:'5%',marginTop:'5%',fontSize: s1, lineHeight:s1, textAlign:'right',width:'90%',color:'white', maxWidth:'400px'}}>
          אני מצהיר/ה כי

          <ol dir="RTL" style={{marginTop:'0px'}}>
            <li>
            אינני משתעל/ת.
            </li>
            <li>
              אין לי חום מעל 38 ולא היה לי בשבוע האחרון.
            </li>
            <li>
              לא הייתי במגע קרוב עם חולה קורונה בשבועיים האחרונים.
            </li>
          </ol>
          </p>

          <br/><br/><br/>
          <table>
            <tr>
              <td>
                <button onClick={this.exitPressed}   style={{fontSize:s2,backgroundColor:'#006400',color:'white'}}>
                חזרה למסך הראשי
                </button>
              </td>
              <td>
                <button onClick={this.checkinPressed}   style={{fontSize:s2,backgroundColor:'#006400',color:'white'}}>
                אישור הצהרת בריאות
                </button>
              </td>
            </tr>
          </table>
        </div>
        }
      </div>
    );
  }
}
