import React from 'react';
import './App.css';
import FormOwner from './FormOwner.js'
import FormEntry from './FormEntry.js'
import FromCustomer from './FormCustomer.js'

export default class App extends React.Component {

  componentDidMount() {
    this.setState ({
      page: 'entry',
      bug:'eyal'
    });
  }

  render() {
     return (
       <div style={{ position: 'absolute', top: 0, left: 0,height:'100%', width:'100%'}}>
          {!this.state || this.state.page==='entry' && <FormEntry app={this}/>}
           {this.state && this.state.page==='owner' && <FormOwner app={this}/>}
           {this.state && this.state.page==='customer' && <FromCustomer app={this}/>}
       </div>
     );
  }

}
