import React from 'react';
import streets from './assets/streets.json';
import cities from './assets/cities.json';

import { QRCode } from 'react-qrcode';

import Combobox from 'react-widgets/lib/Combobox'
import DropdownList from 'react-widgets/lib/DropdownList'

import 'react-widgets/dist/css/react-widgets.css';

var firebase = require("firebase/app");
var auth = require("firebase/auth");
var database = require("firebase/database");


export default class FormOwner extends React.Component {

  state = {
    place: '',
    placename: '',
    visiters: 0
  };

  handleUserChange = (user) => {
    if (user) {

      var date = new Date();
      var dateStr = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
      var timeStr = date.getHours() + ":" + date.getMinutes();

      firebase.database().ref('/owners/'+user.phoneNumber).update({
        lastsession: dateStr + ' ' + timeStr
      });

      this.setState ({
        user:user,
        dateStr:dateStr,
        message : '',
        stage: 'createplace-1'
      });

      firebase.database().ref('/owners/'+user.phoneNumber+'/places').once( 'value',(snapshot) => {
          var places = [];
          snapshot.forEach(function(childSnapshot) {
            if (childSnapshot.val().placename) {
              places.push ({place:childSnapshot.key, name:childSnapshot.val().placename});
            }
          });

          this.setState ({
            places
          });
      });




    } else {
      this.setState ({
        user:'',
        message: 'אנא הזינו מספר טלפון נייד, כדי שנוכל לזהות אתכם',
        stage:'enterphone'});
    }
  }


  constructor(props) {
    super(props);

    var firebaseConfig = {
      apiKey: "AIzaSyAGDkg8qGqgOALEFi75Kl4yBklSJmJQO1I",
      authDomain: "whowashere-8d3b8.firebaseapp.com",
      databaseURL: "https://whowashere-8d3b8.firebaseio.com",
      projectId: "whowashere-8d3b8",
      storageBucket: "whowashere-8d3b8.appspot.com",
      messagingSenderId: "874620106895",
      appId: "1:874620106895:web:e9b473e9d5bf5a00c918fa",
      measurementId: "G-DPPZMPNSDS"
    };

    // Initialize Firebase
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }

    this.state.app = props.app;
    var user = firebase.auth().currentUser;
    if (user) {
      this.setState ({
        user,
        message : 'כדי להתחיל אנא הזן את שם העסק',
        stage: 'createplace-1'
      });
    }
    else {
    }
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged( (user) => this.handleUserChange(user));
  }

  addNames(event) {
    this.setState({names: this.state.names+1});
  }


  loadPlace = (phone, place) => {
    firebase.database().ref('/places/' +  place).once('value').then((snapshot) => {
      if (snapshot.val() && snapshot.val().name) {
        var placeInst = snapshot.val();
        placeInst.key = snapshot.key;
        this.setState({
          'placename': snapshot.val().name,
          placeInst,
          'stage': 'checkin',
          'message': ''
        });

        firebase.database().ref('/users/'+this.state.user.phoneNumber).update({
          place: place,
          placename: snapshot.val().name
        });


        var date = new Date();
        var dateStr = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
        firebase.database().ref('/places/'+this.state.placeInst.key+'/visits/'+dateStr).on( 'value',(snapshot) => {
          if (snapshot && snapshot.val()) {
            var count = 0;
            snapshot.forEach(function(childSnapshot) {
              if (!childSnapshot.val().leave) {
                count++;
              }
            });
            this.setState ({
              visiters : count
            });
          }
        });
      }
    });
  }
  handleChange = (event) => {
    this.setState({[event.target.name] : event.target.value });
    const place = event.target.value;
    if (event.target.name==='valueplace') {
      this.setState({'place':''});
      this.loadPlace (this.state.user.phoneNumber, place);
    }
  }

  exitPressed =  () => {
    this.state.app.setState ({
      page: 'entry'
    });
  }
  createSignPressed = () => {
    this.setState ({
      stage: 'showSign',
      message: ''
    });
  }



  updatePressed =  () => {
    this.initStreestInfo();
    this.setState ({
      valueplacename : this.state.placeInst.name,
      valueplacestreet : this.state.placeInst.street,
      valueplacehousenumber : this.state.placeInst.house,
      valueplacecity : this.state.placeInst.city,
      valueplaceownername : this.state.placeInst.ownerName,
      valueplaceownerid : this.state.placeInst.ownerId,
      valueplacephone : this.state.placeInst.phone,
      valueplaceownerphone : this.state.placeInst.ownerPhone,
      stage: 'editplace'
    });

  }


  savePhoneCodePressed = () => {
    if (!this.state.valuephone) {
      alert ('אנא הזינו את הקוד שקיבלתם בסמס');
      return;
    }
    this.setState ({
      message: 'אנחנו בודקים את הקוד שהזנתם'
    });

    this.state.confirmationResult.confirm(this.state.valuephonecode).then( (result) => {
        // User signed in successfully.
        this.setState ({
          message: 'הקוד אושר, אפשר להמשיך',
          user : result.user,
          stage: 'enterplace'
        });
    }).catch( (error) => {
      this.setState ({
        message: error.message
      })
    });
  }

  savePlacePressed = () => {
    if (!this.validatePlace()) {
      return;
    }

    firebase.database().ref('/places/' +  this.state.valueplace).update({
      name: this.state.valueplacename,
      street: this.state.valueplacestreet,
      house:this.state.valueplacehousenumber,
      city:this.state.valueplacecity,
      ownerName:this.state.valueplaceownername,
      ownerId:this.state.valueplaceownerid,
      phone:this.state.valueplacephone,
      ownerPhone:this.state.valueplaceownerphone
    });

    this.loadPlace (this.state.user.phoneNumber, this.state.valueplace);

  }

  validatePlace = () => {
    if (!this.state.valueplacename) {
      alert ('נא להקליד את שם העסק');
      return false;
    }
    if (!this.state.valueplacestreet) {
      alert ('נא להקליד את שם הרחוב של בית העסק');
      return false;
    }
    if (!this.state.valueplacehousenumber) {
      alert ('נא להקליד את מספר הבית של בית העסק');
      return false;
    }
    if (!this.state.valueplacecity) {
      alert ('נא להקליד את שם היישוב של בית העסק');
      return false;
    }

    if (!this.state.valueplaceownername) {
      alert ('נא להקליד את שם בעל/ת העסק');
      return false;
    }
    if (!this.state.valueplaceownerid) {
      alert ('נא להקליד 9 ספרות של תעודת הזהות של בעל/ת העסק');
      return false;
    }
    if (!this.state.valueplacephone) {
      alert ('נא להקליד את מספר הטלפון של העסק');
      return false;
    }
    if (!this.state.valueplaceownerphone) {
      alert ('נא להקליד את מספר הטלפון הנייד של בעל/ת העסק');
      return false;
    }
    return true;
  }


  saveNewPlacePressed = () => {

    if (!this.validatePlace()) {
      return;
    }


    firebase.database().ref('/places/count').once( 'value', (snapshot) => {
      var count = snapshot.val() || 1000;
      this.state.valueplace = count+1;
      firebase.database().ref('/places').update ({ 'count' : count + 1});

      firebase.database().ref('/places/' +  count).update({
        name: this.state.valueplacename,
        street: this.state.valueplacestreet,
        house:this.state.valueplacehousenumber,
        city:this.state.valueplacecity,
        ownerName:this.state.valueplaceownername,
        ownerId:this.state.valueplaceownerid,
        phone:this.state.valueplacephone,
        ownerPhone:this.state.valueplaceownerphone
      });

      var place = {
        currentplace: count,
        currentplacename : this.state.valueplacename
      };
      place[count] = {
        placename: this.state.valueplacename,
        createdon: Date.now()
      }
      firebase.database().ref('/owners/'+this.state.user.phoneNumber+'/places').update(place);

      this.loadPlace (this.state.user.phoneNumber, count);
    });

  }

  backFromReportPressed = () => {
    this.setState ({
      stage: 'checkin',
      'message': ''
    });

  }


  openPlacePressed = () => {
    this.loadPlace (this.state.user.phoneNumber, this.state.myselectedplace );
  }

  // newPlacePressed = () => {
  //   this.setState ({
  //     stage: 'createplace-1'
  //   })
  // }

  sendPhonePressed = () => {
    if (!this.state.valuephone) {
      alert ('אנא הזינו את מספר הטלפון הנייד שלכם');
      return;
    }

    var phone = this.state.valuephone;
    if ( phone.startsWith('05') || phone.startsWith('07') ) {
      phone = '+972' + phone.substr(1);
    }
    else {
      alert ('אנחנו תומכים רק במכשירים ניידים - לכן המספר צריך להתחיל ב 05 או ב 07');
      return;
    }


    firebase.auth().languageCode = 'he';
    var appVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
      'size': 'invisible',
      'callback': (response) => {
        // this.setState ({'stage':'enterphonecode', message:'אנו נשלח לך קוד בסמס, אותו יש להזין'});
      }
    });

    firebase.auth().signInWithPhoneNumber(phone, appVerifier)
    .then( (confirmationResult) => {
      this.setState ({confirmationResult : confirmationResult});
      this.setState ({'stage':'enterphonecode', message:'אנו נשלח לך קוד בסמס, אותו יש להזין'});
    }).catch( (error) => {
      this.setState ({message: error.message});
    });
  }

  renderQRCode = () => {
    var url = "http://tav.greenshpits.com/?place="+this.state.placeInst.key;
    return (
        <QRCode value={url} />
    )
  }

  initStreestInfo = () => {
    var streetsarray = []
    streets.map ( (data) => {
      if (!streetsarray.includes(data)) {
        streetsarray.push(data);
      }
      return streetsarray.length;
    });

    var ciryarray = []
    cities.map ( (data) => {
      if (!ciryarray.includes(data)) {
        ciryarray.push(data);
      }
      return ciryarray.length;
    });

    this.setState ({
      streets : streetsarray,
      cities: ciryarray
    });

  }
  createPlacePressed = () => {

    this.initStreestInfo();

    this.setState ({
      stage: 'createplace',
      'message': '',
    });
  }

  enterPlacePressed = () => {
    if (this.state.places && this.state.places.length===1) {
        this.loadPlace (this.state.user.phoneNumber, this.state.places[0].place);
    }
    else {
      this.setState ({
        stage: 'enterplace'
      });
    }
  }


  PlaceSign = () => {
    const s2 = "20px";

    return (
      <div style={{width:'100%'}}>
          <div style={{ flexGrow: 1}}>
            <p dir="RTL" style={{ fontSize:'20px'}}>
            אפליקציית ״התו הסגול״
            <br/>
            ברוכים הבאים אל:
            </p>
            <p style={{fontSize:'25px'}}>{this.state.placeInst.name}</p>

            <p dir="RTL" style={{ fontSize:'20px',textAlign:'center'}}>
            בהתאם להנחיות משרד הבריאות, על כל בית עסק לבצע רישום של הנכנסים
            ועל כל לקוח למלא הצהרת בריאות
            <br/>
            לשם כך , אנא היכנסו לכתובת האתר:
            <br/>
            </p>
            <p style={{ fontSize:'25px',textAlign:'center'}}>
            tav.greenshpits.com
            </p>
            <p dir="RTL" style={{ fontSize:'20px',textAlign:'center'}}>
            לאחר הכניסה למערכת, אנא בחרו את העסק שלנו באמצעות הקוד:
            <p dir="RTL"  style={{marginTop:'0px',fontSize:'25px'}}>{this.state.placeInst.key}</p>
            או סירקו את הברקוד הבא:
            </p>
          </div>

          {this.renderQRCode()}
          <br/>
          <button onClick={this.exitPressed}
          style={{height:'50%',fontSize:s2,backgroundColor:'white',color:'black'}}>
          חזרה למסך הראשי
          </button>

        </div>
    )
  };


  render() {
    // const s0 = "15px";
    const s1 = "18px";
    const s2 = "20px";

    return (
      <div style={{textAlign:'center', backgroundColor: (this.state.stage === 'showSign') ? '#FFF' : '#800080', height:'100%', paddingLeft:'5%',paddingRight:'5%'}}>
      <div style={{height:'2%'}}/>
      { this.state.stage !== 'showSign' &&
        <div>
        <p style={{fontSize: s1, lineHeight:s1,color:'white', maxWidth:'400px',paddingLeft:'5%',paddingRight:'5%'}}>
        ביחד ננצח את הקורונה
        <br/>
        מיישמים את הנחיות משרד הבריאות
        </p>
        <p style={{marginTop:'5%',fontSize: s2, lineHeight:s2, color:'white', maxWidth:'400px'}}>
        התו הסגול
        </p>
        <p style={{marginTop:'5%',fontSize: s1, lineHeight:s1, color:'white', maxWidth:'400px'}}>
        {this.state.message}
        </p>
        </div>

      }

      { this.state.stage === 'enterphone'  &&
      <div style={{textAlign:'center'}}>
      <br/>
      <input name="valuephone"
            placeholder="מספר טלפון נייד"
            onChange={this.handleChange}
            style={{marginTop:'3%',padding:'5%',fontSize:s2, lineHeight:s2, width:'60%',textAlign:'center'}} type="text" value={this.state.valuephone} />
      <br/><br/><br/>
      <button onClick={this.sendPhonePressed}  style={{fontSize:s2, padding:'5%',height:'8%',width:'55%',backgroundColor:'#006400',color:'white'}}>
      שליחת מספר טלפון נייד
      </button>

      <div id='sign-in-button'/>

      </div>
      }

      { this.state.stage === 'enterphonecode'  &&
      <div style={{textAlign:'center'}}>
      <br/>
      <input name="valuephonecode"
            placeholder="קוד שמגיע בסמס"
            onChange={this.handleChange}
            style={{marginTop:'3%',padding:'5%',fontSize:s2, lineHeight:s2, width:'60%',textAlign:'center'}} type="text" value={this.state.valuephonecode} />
      <br/><br/><br/>
      <button onClick={this.savePhoneCodePressed}  style={{fontSize:s2, padding:'5%',height:'8%',width:'55%',backgroundColor:'#006400',color:'white'}}>
      שליחת קוד זיהוי מהסמס
      </button>

      </div>
      }

      { this.state.stage === 'createplace-1' &&
      <div style={{textAlign:'center'}}>
      <p dir="rtl" style={{marginTop:'5%',fontSize: s1, lineHeight:s1, textAlign:'right',color:'white', maxWidth:'400px'}}>
        בעל/ת עסק יקר/ה,
        <br/>
        אפליקציית ״התו הסגול״ תסייע לך להפעיל את העסק שלך תוך שמירה וקיום הנחיות משרד הבריאות, ללא כל מאמץ מצידך או צורך בהשקעת כח אדם לרישום המבקרים.
        <br/>
        כדי לבקר אצלך בעסק, הלקוחות יאשרו הצהרת בריאות, טרם הכניסה וידווחו גם על סיום הביקור בבית העסק.
        <br/>
        כך, הנתונים על המבקרים בעסק שלך יישמרו בדיוק מירבי.
        <br/>
        במידת הצורך בחקירה אפידמיולוגית, יועברו הנתונים על ידינו למשרד הבריאות בלבד !
        <br/>
        במסך המיועד לבעלי העסקים, תוכל/י לראות בכל רגע נתון את מספר המבקרים בבית העסק שלך.

        <br/>
        <br/>
        איך מתחילים ?
        <ol dir="RTL" style={{marginTop:'0px'}}>
        <li>
        הזנ/י את פרטי בית העסק.
        </li>
        <li>
        בשלב הבא יינתן לך קוד זיהוי לבית העסק, וכן ברקוד.
        </li>
        <li>
        הדפס/י את הדף עם קוד העסק והברקוד ותלה/י באופן בולט בכניסה לבית העסק.
        </li>
        <li>
        במידה ובבעלותך מספר בתי עסק / סניפים, יש לבצע רישום מחודש לכל בית עסק / סניף.
        </li>
        <li>
        וזהו, שיהיה לך בהצלחה בהפעלת העסק.
        </li>
        </ol>

      </p>

      <table style={{marginLeft:'3%'}}>
        <tr>
          <td>
            <button onClick={this.createPlacePressed}  style={{fontSize:s2, width:'100%',backgroundColor:'#006400',color:'white'}}>
            רישום עסק חדש
            </button>
          </td>
          <td>
        {this.state.places && this.state.places.length>0 &&
            <button onClick={this.enterPlacePressed}  style={{fontSize:s2, width:'100%',backgroundColor:'#006400' ,color:'white'}}>
            כניסה לעסק רשום
            </button>
          }
          </td>
        </tr>
      </table>
      </div>

    }
      { (this.state.stage === 'createplace' || this.state.stage === 'editplace') &&
      <div style={{textAlign:'center'}}>

      <input name="valueplacename"
        placeholder="נא להקליד את שם העסק"
        style={{color:'white',backgroundColor:'black', marginTop:'3%',padding:'2%',fontSize:s1, lineHeight:s1, width:'60%',textAlign:'center'}}
        type="text"
        value={this.state.valueplacename}
        onChange={this.handleChange} />
      <br/>

      <Combobox
      name="valueplacestreet"
      placeholder="שם הרחוב"
      style={{color:'white',backgroundColor:'transparent',marginTop:'1%',padding:'2%',marginLeft:'15%', width:'70%'}}
      inputProps={{style:{color:'white',backgroundColor:'black',fontSize:s1, lineHeight:s1}}}
      value={this.state.valueplacestreet}
      onChange={valueplacestreet => this.setState({ valueplacestreet })}
      dropUp
      isRtl
      filter='contains'
      data={this.state.streets}
      />

      <input name="valueplacehousenumber"
        placeholder="מספר הבית"
        style={{color:'white',marginTop:'1%',backgroundColor:'black',padding:'2%',fontSize:s1, lineHeight:s1, width:'60%',textAlign:'center'}}
        type="numeric"
        value={this.state.valueplacehousenumber}
        onChange={this.handleChange} />
        <br/>

        <Combobox
        name="valueplacecity"
        placeholder="שם היישוב"
        style={{color:'white',backgroundColor:'transparent',marginTop:'1%',padding:'2%',marginLeft:'15%', width:'70%'}}
        inputProps={{style:{color:'white',backgroundColor:'black',fontSize:s1, lineHeight:s1}}}
        value={this.state.valueplacecity}
        onChange={valueplacecity => this.setState({ valueplacecity })}
        dropUp
        isRtl
        filter='contains'
        data={this.state.cities}
        />
      <input name="valueplaceownername"
        placeholder="שם בעל/ת העסק"
        style={{color:'white',marginTop:'1%',backgroundColor:'black',padding:'2%',fontSize:s1, lineHeight:s1, width:'60%',textAlign:'center'}}
        type="text"
        value={this.state.valueplaceownername}
        onChange={this.handleChange} />
        <br/>
      <input name="valueplaceownerid"
        placeholder="מספר תעודת זהות ב-9 ספרות"
        style={{color:'white',marginTop:'1%',backgroundColor:'black',padding:'2%',fontSize:s1, lineHeight:s1, width:'60%',textAlign:'center'}}
        type="text"
        value={this.state.valueplaceownerid}
        onChange={this.handleChange} />
        <br/>
      <input name="valueplacephone"
        placeholder={this.state.valueplacephone ? this.state.valueplacephone : "מספר טלפון העסק"}
        style={{color:'white',marginTop:'1%',backgroundColor:'black',padding:'2%',fontSize:s1, lineHeight:s1, width:'60%',textAlign:'center'}}
        type="text"
        value={this.state.valueplacephone}
        onChange={this.handleChange} />
        <br/>
      <input name="valueplaceownerphone"
        placeholder="טלפון נייד של בעל/ת העסק"
        style={{color:'white',marginTop:'1%',backgroundColor:'black',padding:'2%',fontSize:s1, lineHeight:s1, width:'60%',textAlign:'center'}}
        type="text"
        value={this.state.valueplaceownerphone}
        onChange={this.handleChange} />
      <br/><br/>
      { this.state.stage === 'createplace' &&
      <button onClick={this.saveNewPlacePressed}  style={{fontSize:s2, padding:'5%',width:'80%',backgroundColor:'#006400',color:'white'}}>
      לחץ כאן להמשך ולקבלת קוד לבית העסק
      </button>
      }
      { this.state.stage === 'editplace' &&
      <button onClick={this.savePlacePressed}  style={{fontSize:s2, padding:'5%',width:'80%',backgroundColor:'#006400',color:'white'}}>
      לחץ כאן לשמירה של השינויים
      </button>
      }
      </div>
      }

      { this.state.stage === 'enterplace'  &&
      <div style={{textAlign:'center'}}>
      <p dir="rtl" style={{marginTop:'10%',fontSize: s1, lineHeight:s1, color:'white', maxWidth:'400px'}}>
        אנא בחר את בית העסק שבבעלותך, בו תרצה לצפות
      </p>
      <DropdownList
    dropUp
    isRtl
    placeholder="בחר אחד מהעסקים שהקמת"
    style={{fontSize:s2, padding:'5%',height:'8%',backgroundColor:'#006400',color:'white'}}
    data={this.state.places}
    onSelect={ (key) => {this.state.myselectedplace=key.place }}
    textField='name'
    valueField='place'
      />
        <br/><br/><br/>
            <button onClick={this.openPlacePressed}  style={{fontSize:s2, padding:'5%',height:'8%',width:'55%',backgroundColor:'#006400',color:'white'}}>
            לצפייה בנתוני העסק שבחרתי
            </button>
        </div>
      }

      { this.state.stage === 'showSign'  &&
          <div>
           {this.PlaceSign()}
          </div>
      }

      { this.state.stage === 'checkin'  &&
      <div style={{textAlign:'center'}}>
        <label style={{color:'white',fontSize: '30px'}}>
          קוד: {this.state.placeInst.key}
          <br/>
          שם: {this.state.placeInst.name}
        </label>
        <br/><br/>
        {this.renderQRCode()}
        <br/><br/><br/>

        <label style={{color:'white',fontSize: '30px'}}>
          מספר מבקרים כעת: {this.state.visiters}
        </label>
        <br/><br/><br/>

        <table>
          <tr>
            <td>
              <button onClick={this.exitPressed}   style={{fontSize:s2,backgroundColor:'#006400',color:'white'}}>
              חזרה למסך הראשי
              </button>
            </td>
            <td>
              <button onClick={this.updatePressed}   style={{fontSize:s2,backgroundColor:'#006400',color:'white'}}>
              עדכון פרטי העסק
              </button>
            </td>
            <td>
              <button onClick={this.createSignPressed}   style={{fontSize:s2,backgroundColor:'#006400',color:'white'}}>
              הפקת שלט לעסק
              </button>
            </td>
          </tr>
        </table>
      <br/><br/><br/>
      </div>
      }
      </div>
    );
  }
}
