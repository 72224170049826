import React from 'react';
import bg1 from './assets/bg1.png';

var firebase = require("firebase/app");

// must be listed before other Firebase SDKs


export default class FormEntry extends React.Component {

  state = {
    page: 'main',
    names: 3
  };

  customerSelected= () => {
    this.state.app.setState( {page:'customer'});
  }

  ownerSelected= () => {
    this.state.app.setState( {page:'owner'});
  }

  constructor(props) {
    super(props);

    this.state.app = props.app;

    var firebaseConfig = {
      apiKey: "AIzaSyAGDkg8qGqgOALEFi75Kl4yBklSJmJQO1I",
      authDomain: "whowashere-8d3b8.firebaseapp.com",
      databaseURL: "https://whowashere-8d3b8.firebaseio.com",
      projectId: "whowashere-8d3b8",
      storageBucket: "whowashere-8d3b8.appspot.com",
      messagingSenderId: "874620106895",
      appId: "1:874620106895:web:e9b473e9d5bf5a00c918fa",
      measurementId: "G-DPPZMPNSDS"
    };

    // Initialize Firebase
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
  }


  render() {
    const s0 = "15px";
    const s1 = "20px";
    const s2 = "35px";
    return (
      <div dir="rtl"  style={{textAlign:'center', backgroundColor:'#800080', height:'100%', paddingLeft:'10%',paddingRight:'10%'}}>
      <div style={{height:'2%'}}/>
      <p style={{fontSize: s1, lineHeight:s1, textAlign:'center',color:'white', maxWidth:'400px'}}>
      ביחד ננצח את הקורונה
      <br/>
      מיישמים את הנחיות משרד הבריאות
      </p>
      <p style={{marginTop:'5%',fontSize: s2, lineHeight:s2,color:'white', maxWidth:'400px'}}>
      התו הסגול
      </p>
      <p style={{marginTop:'5%',fontSize: s1, lineHeight:s1,color:'white', maxWidth:'400px'}}>
      אפליקציה לשימוש התושבים ובעלי העסקים
      </p>
      <br/><br/>
      <button onClick={this.customerSelected}  style={{fontSize:s2, width:'75%',backgroundColor:'#006400',color:'white'}}>
      לקוח/ה
      </button>
      <br/><br/><br/>

        <button onClick={this.ownerSelected}  style={{fontSize:s2, width:'75%',backgroundColor:'#006400',color:'white'}}>
        בעל/ת עסק
        </button>
        <br/><br/><br/>
        <p style={{marginTop:'5%',fontSize: s0, lineHeight:s0, color:'white', maxWidth:'400px'}}>
        הנתונים חסויים ובמידת הצורך יועברו למשרד הבריאות במקרים של חקירה אפידמיולוגית.
        </p>
        <p style={{marginTop:'5%',fontSize: s0, lineHeight:s0, color:'white', maxWidth:'400px'}}>
        האפליקציה נתרמה לציבור ע״י חברת
        <br/>
        <a href="http://www.greenshpits.com" style={{color:'white'}}>גריןשפיץ</a>
        </p>

      </div>
    );
  }
}
